import React, { useState, useContext } from 'react'

import { useHistory } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Alert from '@material-ui/lab/Alert';
import { CircularProgress } from '@material-ui/core/';

import { useValidUsername } from '../../hooks/useAuthHooks'
import { Username } from '../../components/authComponents'

import { AuthContext } from '../../contexts/authContext'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Logo from '../../assets/img/logo.webp';
import FooterLogo from '../../assets/img/footer-logo.webp';

const useStyles = makeStyles({
  root: {
    height: '100vh',
  },
  hover: {
    '&:hover': { cursor: 'pointer' },
  },
  text: {
    textAlign: 'center',
  },
})

export default function RequestCode() {
  const classes = useStyles()

  const { username, setUsername, usernameIsValid } = useValidUsername('')
  const [error, setError] = useState('')
  const [resetSent, setResetSent] = useState(false)
  const [screenLoader, setScreenLoader] = useState(false)

  const isValid = !usernameIsValid || username.length === 0

  const history = useHistory()

  const authContext = useContext(AuthContext)

  const sendCodeClicked = async () => {
    setScreenLoader(true);
    try {
      await authContext.sendCode(username)
      setScreenLoader(false);
      setResetSent(true)
    } catch (err) {
      setScreenLoader(false);
      setError('Unknown user')
    }
  }

  const emailSent = (
    <>
      <Box mt={1}>
        <Alert severity="success">{`Reset Code Sent to `}<strong>{username}</strong></Alert>
      </Box>
      <Box mt={4}>
        <Button onClick={() => history.push('forgotpassword')} color="primary" variant="contained">
          Reset Password
        </Button>
      </Box>
    </>
  )

  const sendCode = (
    <>
      <Box width="80%" m={1}>
        <Username usernameIsValid={usernameIsValid} setUsername={setUsername} />
      </Box>
      <Box mt={2}>
        <Typography color="error" variant="body2">
          {error}
        </Typography>
      </Box>

      <Box mt={2}>
        <Grid container direction="row" justify="center">
          {/* <Box m={1}>
            <Button color="secondary" variant="contained" onClick={() => history.goBack()}>
              Cancel
            </Button>
          </Box> */}
          <Box m={1}>
            <Button disabled={isValid} color="primary" variant="contained" onClick={sendCodeClicked}>
              Send Code
            </Button>
          </Box>
        </Grid>
      </Box>
      <Box className='auth-secondary-text'>
        <Typography>Remember your password? <a onClick={() => history.push('signin')}>Sign In</a></Typography>
      </Box>
    </>
  )

  return (
    <Grid className='authentication-box' xs={11} sm={6} lg={4} container direction="row" justify="center" alignItems="center" item>
      {screenLoader ? <Box className='screenLoader'><CircularProgress size={50} thickness={3} /></Box> : null}
      <Grid className='authentication-inner-box' container direction="column" justify="center">
        <Grid container direction="column" justify="center" alignItems="center">
          <Box>
            <Grid container>
              <List className='auth-header'>
                <ListItem className='auth-header-logo'><img src={Logo} alt="" /></ListItem>
                <ListItem className='auth-header-text'><Typography variant="h3">Forgot password</Typography></ListItem>
              </List>
            </Grid>
          </Box>

          {resetSent ? emailSent : sendCode}
          <Box className='auth-credit-text'>
          <Typography className='poweredBy-text'>Powered by <a href='https://edgevana.com/' target='_blank'><img src={FooterLogo} width="105" /></a></Typography>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  )
}
