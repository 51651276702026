import React, { useState, useContext, useEffect } from 'react'

import { useHistory } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Alert from '@material-ui/lab/Alert';
import { CircularProgress } from '@material-ui/core/';

import { useValidPassword, useValidUsername } from '../../hooks/useAuthHooks'
import { Password, Username } from '../../components/authComponents'

import { AuthContext } from '../../contexts/authContext'
import Logo from '../../assets/img/logo.webp';
import FooterLogo from '../../assets/img/footer-logo.webp';
import { getCognitoUser } from '../../libs/cognito';
import TextField from '@material-ui/core/TextField'
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles({
  root: {
    height: '100vh',
  },
  hover: {
    '&:hover': { cursor: 'pointer' },
  },
})

const SignIn = () => {
  const classes = useStyles()

  const { username, setUsername, usernameIsValid } = useValidUsername('')
  const { password, setPassword, passwordIsValid } = useValidPassword('')
  const [isFirstLogin, setIsFirstLogin] = useState(false)
  const [cogUser, setCogUser] = useState({completeNewPasswordChallenge: ''})
  const [cogUserAttrs, setCogUserAttrs] = useState(false)
  const [error, setError] = useState('')
  const [screenLoader, setScreenLoader] = useState(false)
  const [newPassword, setNewPassword] = useState('')
  const [isPasswordChanged, setIsPasswordChanged] = useState(false)
  const [typeIn, setTypeIn] = useState('password')
  const [typeInChange, setTypeInChange] = useState('password')

  const isValid = !usernameIsValid || username.length === 0 || !passwordIsValid || password.length === 0

  const history = useHistory()

  const authContext = useContext(AuthContext)

  const signInClicked = async (e) => {
    e.preventDefault();
    setScreenLoader(true);
    try {
      await authContext.signInWithEmail(username, password).then((res) => {
        setScreenLoader(false);
        history.push('dashboard')
      }).catch((err) => {
        if (typeof err.unconfirmed !== 'undefined' && err.unconfirmed) {
          console.log(err)
          setScreenLoader(false);
          setIsFirstLogin(true)
          setCogUser(err.unconfirmed_user)
          setCogUserAttrs(err.userAttributes)
        }else{
          const exceptionErr = err.toString().split(": ");
          if(exceptionErr.length > 0){
            setError(exceptionErr[1])
          }
          setScreenLoader(false);
        }
      })
    } catch (err) {
      setScreenLoader(false);
      if (err.code === 'UserNotConfirmedException') {
        history.push('verify')
      } else {
        setError(err.message)
      }
    }
  }

  const passwordResetClicked = async () => {
    history.push('requestcode')
  }

  const handleNewPasswordSubmit = (e) => {
    e.preventDefault();
    setScreenLoader(true);
    if(typeof cogUser.completeNewPasswordChallenge !== 'undefined'){
      cogUser.completeNewPasswordChallenge(newPassword, cogUserAttrs, {
        onSuccess: (result) => {
          setScreenLoader(false);
          setIsPasswordChanged(true)
          authContext.signOut()
          setTimeout(() => {
            window.location.reload()
          }, 4000)
        },
        onFailure: (err) => {
          setScreenLoader(false);
          alert('Some error occured! please try again later.')
        }
      });
    }
  }

  return (
    <>
    <Grid className='authentication-box' xs={11} sm={6} lg={4} container direction="row" justify="center" alignItems="center" item>
      {screenLoader ? <Box className='screenLoader'><CircularProgress size={50} thickness={3} /></Box> : null}
      <Grid className='authentication-inner-box' container direction="column" justify="center">
        

          <form onSubmit={(e) => signInClicked(e)}>
          <Grid container>
            <Box>
              <Grid container>
                <List className='auth-header'>
                  <ListItem className='auth-header-logo'><img src={Logo} alt="" /></ListItem>
                  <ListItem className='auth-header-text'><Typography variant="h3">Sign in</Typography></ListItem>
                </List>
              </Grid>
            </Box>

            {/* Sign In Form */}
            <Box width="80%" m={1} className='bc-p-0'>
              {/* <Email emailIsValid={emailIsValid} setEmail={setEmail} /> */}
              <Username usernameIsValid={usernameIsValid} setUsername={setUsername} />{' '}
            </Box>
            <Box width="80%" m={1}>
              <div className='relative-wrapper'>
                <Password label="Password" passwordIsValid={passwordIsValid} setPassword={setPassword} typeIn={typeIn} />
                <div className='password-eye-wrapper'>
                  {typeIn === 'password' ? <Visibility className='password-eye' onClick={() => setTypeIn('text')} /> : <VisibilityOff className='password-eye' onClick={() => setTypeIn('password')} />}
                </div>
              </div>
              <Grid container direction="row" justify="flex-start" alignItems="center">
                <Box className='forgot-password-text' mt={2}>
                  <Typography variant="body2" onClick={passwordResetClicked}>
                    Forgot Password?
                  </Typography>
                </Box>
              </Grid>
            </Box>

            {/* Error */}
            {error ? <Box mb={1}>
              <Alert severity="error">{error}</Alert>
            </Box> : null}

            {/* Buttons */}
            <Box mt={2}>
              <Grid container direction="row" justify="center">
                {/* <Box m={1}>
                    <Button color="secondary" variant="contained" onClick={() => history.goBack()}>
                      Cancel
                    </Button>
                  </Box> */}
                <Box m={1}>
                  {/* <Button type="submit" disabled={isValid} color="primary" variant="contained" onClick={signInClicked}> */}
                  <Button type="submit" disabled={isValid} color="primary" variant="contained">
                    Sign In
                  </Button>
                </Box>
              </Grid>
            </Box>
            {/* <Box className='auth-secondary-text'>
              <Typography>Don't have an account? <a onClick={() => history.push('signup')}>Create account</a></Typography>
            </Box> */}
          <Box className='auth-credit-text'>
            <Typography className='poweredBy-text'>Powered by <a href='https://edgevana.com/' target='_blank'><img src={FooterLogo} width="105" /></a></Typography>
          </Box>
        </Grid>
          </form>
          
      </Grid>
    </Grid>
    {isFirstLogin ? <div className='isFirstLogin-modal'>
        {screenLoader ? <Box className='screenLoader'><CircularProgress size={50} thickness={3} /></Box> : null}
        <form onSubmit={(ev) => handleNewPasswordSubmit(ev)}>
          {isPasswordChanged ? <>
          <Box mb={1}>
              <div className="success-toast-custom">
                <h5>New password created</h5>
                <p>You will be shortly redirected to Sign In screen to Sign In with new password.</p>
              </div>
          </Box>
        </> : <>
          <div className='new-pw-header'>
            <Box>
              <Grid container>
                <List className='auth-header'>
                  <ListItem className='auth-header-logo'><img src={Logo} alt="" /></ListItem>
                  <ListItem className='auth-header-text'><Typography variant="h3">Change Password</Typography></ListItem>
                </List>
              </Grid>
              <p style={{textAlign: 'center', color: '#fff', fontSize: 17}}>Please create a new password to enter into portal</p>
            </Box>
          </div>
          <Box m={1} className='form-group'>
            <div className='relative-wrapper'>
              <TextField fullWidth type={typeInChange} variant="outlined" label='New Password' onChange={(e) => setNewPassword(e.target.value)} />
              <div className='password-eye-wrapper'>
                {typeInChange === 'password' ? <Visibility className='password-eye' onClick={() => setTypeInChange('text')} /> : <VisibilityOff className='password-eye' onClick={() => setTypeInChange('password')} />}
              </div>
            </div>
          </Box><br/>
          <Box m={1}>
            {screenLoader ? <Button className='new-pw-btn' type="submit" color="primary" variant="contained" disabled={true}>Processing...</Button> : <Button className='new-pw-btn' type="submit" color="primary" variant="contained" disabled={newPassword === '' || newPassword.length < 8 ? true : false}>Submit</Button>}
          </Box>
        </>}
        </form>
    </div> : null}
    </>
  )
}

export default SignIn
