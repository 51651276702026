import React, { useState, useEffect, useContext } from "react";
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { ComposableMap, Geographies, Geography, Marker } from "react-simple-maps";
import axios from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Dialog, DialogContent, DialogContentText, DialogTitle, CircularProgress } from '@material-ui/core'
import Swal from 'sweetalert2';
import { CSVLink } from "react-csv";
import { AuthContext } from '../contexts/authContext'
import { useHistory } from 'react-router-dom'
import toast from 'react-hot-toast';

export default function MapChart({ setTooltipContent, setDialogContent, setOpen, setDialogTitle }) {
  const [mapData, setMapData] = useState([]);
  const [mapGrouped, setMapGrouped] = useState(false);
  const [srvServers, setSrvServers] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [citiesList, setCitiesList] = useState([]);
  const [siteCitiesList, setCitiesListSite] = useState([]);
  const [siteCitiesListJSON, setSiteCitiesListJSON] = useState([]);
  const [serversList, setServersList] = useState([]);
  const [totalUtil, setTotalUtil] = useState(0);
  const [serverDetailList, setServerDetailList] = useState([]);
  const [serverDetailOpen, setServerDetailOpen] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [assignedText, setAssignedText] = useState(0);
  const [totalServerText, setTotalServerText] = useState(0);
  const [pendingServerText, setPendingServerText] = useState(0);
  const [sitesServers, setSitesServers] = useState([]);
  const [csvButton, setCsvButton] = useState(0);
  const [screenLoader, setScreenLoader] = useState(false)
  const [serverStock, setServerStock] = useState([]);
  const [extraColumns, setExtraColumns] = useState([]);
  const [extraColumnsTotal, setExtraColumnsTotal] = useState([]);
  const [csvHeaders, setCsvHeaders] = useState([]);
  const [clientHeaders, setClientHeaders] = useState([]);
  const [csvStock, setCsvStock] = useState([]);
  const [clientStock, setClientStock] = useState([]);
  const [totalSv, setTotalSv] = useState([]);
  const [availServer, setAvailServer] = useState(0);
  const [availServerTotal, setAvailServerTotal] = useState(0);
  const [asgServer, setAsgServer] = useState(0);
  const [remServer, setRemServer] = useState(0);
  const history = useHistory()
  const authCon = useContext(AuthContext);

  const geoUrl = "https://raw.githubusercontent.com/deldersveld/topojson/master/world-countries.json";
  // const geoUrl = "https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/main/topojson-maps/world-110m.json";

  const authTokenStr = JSON.parse(sessionStorage.getItem('ath'));

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const createCsvData = () => {
    if (mapGrouped) {
      let csvList = [];
      Object.keys(mapGrouped).map((idx_country, country_data) => {
        Object.keys(mapGrouped[idx_country]).map((idx_city, city_data) => {
          let c = mapGrouped[idx_country][idx_city][0];

          let tmpCSV = {};
          tmpCSV['location'] = c.location;
          tmpCSV['region'] = idx_country;
          tmpCSV['metro'] = idx_city;
          tmpCSV['val_servers'] = mapGrouped[idx_country][idx_city].length;
          tmpCSV['avl_servers'] = getAvailableServers(c.location);
          tmpCSV['asg_servers'] = mapGrouped[idx_country][idx_city].length;
          tmpCSV['rem_servers'] = tmpCSV['avl_servers'] - tmpCSV['asg_servers'];
          tmpCSV['server_type'] = c.cpu;
          tmpCSV['date'] = c.date;
          tmpCSV['utilization'] = ((tmpCSV['asg_servers'] / tmpCSV['avl_servers']) * 100).toFixed(0) + '%';
          csvList.push(tmpCSV);
        });
      });

      let finalCsv = csvList;
      if (siteCitiesList.length > 0) {
        finalCsv = finalCsv.concat(siteCitiesList);
      }

      setCsvData(finalCsv);
    }
  }
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}api/0.1/serverStock`, { headers: { Authorization: sessionStorage.getItem('ath') } }).then((res) => {
      setServerStock(res.data);
      if (res.data.length > 0) {
        let extraCols = [];
        res.data.map((item) => {
          extraCols.push({name: item.name, sku: item.sku, total: 0});
        })
        setExtraColumns(extraCols);
        setExtraColumnsTotal(extraCols);

        let csvHeaderArr = [
          { label: "Location", key: "location" },
          { label: "Region", key: "region" },
          { label: "Metro", key: "metro" },
          { label: "Available Servers", key: "avl_servers" },
          { label: "Assigned Servers", key: "asg_servers" },
          { label: "Remaining Servers", key: "rem_servers" },
          // { label: "Server Type", key: "server_type" },
          // { label: "Date", key: "date" },
          { label: "Server Utilization", key: "utilization" }
        ];
        if (extraCols.length > 0) {
          extraCols.map((extraColItem) => {
            csvHeaderArr.push({ label: extraColItem.name, key: extraColItem.name })
          })
        }
        // csvHeaderArr.push({ label: "Total", key: "total" });
        setCsvHeaders(csvHeaderArr);
      }
    }).catch((error) => {
      sessionStorage.clear();
      localStorage.clear();
      history.push('/signin');
    })
  }, []);

  const listAllLocations = (mapGroupedData) => {
    let cityList = [];
    let csvList = [];
    let finalArrList = []
    if (mapGroupedData) {

      Object.keys(mapGroupedData).map((idx_country, country_data) => {
        Object.keys(mapGroupedData[idx_country]).map((idx_city, city_data) => {
          let c = mapGroupedData[idx_country][idx_city][0];

          let tmpCity = {};
          let tmpCSV = {};
          let locationNameArr = c.location.split(',');
          let locationNameArrNew = '';
          if (locationNameArr.length > 0) {
            let tmpMapItem = [];
            locationNameArr.map((mappingItem) => {
              tmpMapItem.push(mappingItem.trim())
            })
            if (tmpMapItem.length > 0) {
              locationNameArrNew = tmpMapItem.join(", ");
            }
          }

          tmpCity['location'] = locationNameArrNew !== '' ? locationNameArrNew : c.location;
          tmpCity['region'] = idx_country;
          tmpCity['metro'] = idx_city;
          tmpCity['val_servers'] = mapGroupedData[idx_country][idx_city].length;
          tmpCity['avl_servers'] = getAvailableServers(c.location);
          tmpCity['asg_servers'] = mapGroupedData[idx_country][idx_city].length;
          tmpCity['rem_servers'] = tmpCity['avl_servers'] - tmpCity['asg_servers'];
          tmpCity['server_type'] = c.cpu;
          tmpCity['date'] = c.date;
          tmpCity['utilization'] = ((tmpCity['asg_servers'] / tmpCity['avl_servers']) * 100).toFixed(0) + '%';
          cityList.push(tmpCity);

          tmpCSV['location'] = c.location;
          tmpCSV['region'] = idx_country;
          tmpCSV['metro'] = idx_city;
          tmpCSV['val_servers'] = mapGroupedData[idx_country][idx_city].length;
          tmpCSV['avl_servers'] = tmpCity['avl_servers'];
          tmpCSV['asg_servers'] = mapGroupedData[idx_country][idx_city].length;
          tmpCSV['rem_servers'] = tmpCSV['avl_servers'] - tmpCSV['asg_servers'];
          tmpCSV['server_type'] = c.cpu;
          tmpCSV['date'] = c.date;
          tmpCSV['utilization'] = ((tmpCSV['asg_servers'] / tmpCSV['avl_servers']) * 100).toFixed(0) + '%';
          csvList.push(tmpCSV);
        });
      });
    
      setCitiesList(cityList);
      setCsvData(csvList);
    }
  }
  
 
  const dynamicSort = (property) => {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
      return result * sortOrder;
    }
  }

  useEffect(() => {
    let tmpMap = [];
    var srv_data = [];
    var client_data = []
    var srv_data_grouped = {};

    setScreenLoader(true);

    axios.get('../svg/mapBase.svgd')
      .then(res => {
        axios.get(`${process.env.REACT_APP_API_URL}api/0.1/srv`, { headers: { Authorization: sessionStorage.getItem('ath') } })
          .then(resp => {
            srv_data = resp.data;
            setSrvServers(srv_data);
            if (resp.data) {
              // axios.get(`${process.env.REACT_APP_API_URL}api/0.1/locinfo`, { headers: { Authorization: sessionStorage.getItem('ath') } }).then((mapRes) => {
              axios.get(`${process.env.REACT_APP_API_URL}api/0.1/sites`, { headers: { Authorization: sessionStorage.getItem('ath') } }).then((mapRes) => {

                if (mapRes.data.length > 0) {
                  setSitesServers(mapRes.data);
                  let cityList_sites = [];
                  mapRes.data.map((locationItem) => {

                    let tmp = {};
                    tmp['markerOffset'] = 0;
                    tmp['name'] = locationItem.site;
                    tmp['coordinates'] = [locationItem.lng, locationItem.lat];
                    tmp['params'] = [locationItem.country, locationItem.city];
                    tmpMap.push(tmp);

                    let tmpCity = {};
                    tmpCity['location'] = locationItem.site;
                    tmpCity['region'] = locationItem.country;
                    tmpCity['metro'] = locationItem.city;
                    tmpCity['avl_servers'] = 0;
                    tmpCity['asg_servers'] = 0;
                    tmpCity['rem_servers'] = tmpCity['avl_servers'] - tmpCity['asg_servers'];
                    tmpCity['server_type'] = 'AMD Milan 7413 EPYC';
                    tmpCity['date'] = '02/10/2022';
                    tmpCity['utilization'] = '0%';
                    cityList_sites.push(tmpCity);
                  });

                  let clientHeaders = [
                    { label: "First Name", key: "name" },
                    { label: "Last Name", key: "lname" },
                    { label: "Company", key: "companyname" },
                    { label: "Email", key: "email" },
                    { label: "Registration Date", key: "date" },
                    { label: "Location", key: "location" },
                    { label: "CPU", key: "cpu" },
                    { label: "Memory", key: "memory" },
                    { label: "Network", key: "network" },
                    { label: "IP Address", key: "primaryIp" },
                    // { label: "Bandwidth", key: "bwusage" },
                    { label: "Monthly Cost", key: "cost" },
                    { label: "Payment Type", key: "paymentType" },
                  ];

                  setClientHeaders(clientHeaders);
                  let clientInfoArr = [];
                  srv_data.map((client) => {
                    if(client.status == 'active'){
                      let clientInfo = {};
                      clientInfo['cost'] = client.cost;
                      clientInfo['cpu'] = client.cpu;
                      clientInfo['primaryIp'] = client.primaryIp;
                      clientInfo['date'] = client.regdate;
                      clientInfo['companyname'] = client.client.companyname;
                      clientInfo['email'] = client.client.email;
                      clientInfo['lname'] = capitalizeFirstLetter(`${client.client.lastname}`);
                      clientInfo['name'] = capitalizeFirstLetter(`${client.client.firstname}`);
                      // clientInfo['bwusage'] = client.bwusage;
                      clientInfo['location'] = client.location;
                      clientInfo['memory'] = client.memory;
                      clientInfo['network'] = client.network;
                      clientInfo['paymentType'] = client.paymentmethodname;
                      clientInfoArr.push(clientInfo);
                    }
                  })
                  const sortedClients = clientInfoArr.sort(dynamicSort("name"));
                  setClientStock(sortedClients);

                  if (srv_data) {
                    srv_data.map((data) => {
                      let key1 = undefined
                      let key2 = undefined
                      
                      for (const element of cityList_sites) {
                        if (data.location === element.location) {
                          key1 = element.region;
                          key2 = element.metro;
                        }
                      }

                      if (srv_data_grouped[key1] === undefined) {
                        srv_data_grouped[key1] = {}
                        srv_data_grouped[key1][key2] = []
                        srv_data_grouped[key1][key2].push(data)
                      } else {
                        if (srv_data_grouped[key1][key2] === undefined) {
                          srv_data_grouped[key1][key2] = [];
                          srv_data_grouped[key1][key2].push(data)
                        } else {
                          srv_data_grouped[key1][key2].push(data)
                        }
                      }
                    });
                    setMapGrouped(srv_data_grouped);
                    listAllLocations(srv_data_grouped)
                  }

                  setCitiesListSite(cityList_sites);
                  setMarkers(tmpMap);
                  // createCsvData();
                }
                setScreenLoader(false);
              });

            }
            // setScreenLoader(false);
          }).catch(() => {
            setScreenLoader(false);
            toast.error("Your session has timed out. Please sign in again.")
            // alert('Some error occured! System is unable to fetch data');
            sessionStorage.clear();
            localStorage.clear();
            history.push('/signin');
          })
      })
  }, [])

  useEffect(() => {
    if(csvButton < 2){
      const interval = setInterval(() => {
        if (citiesList.length > 0) {
          
          let avl_arr = [];
          let asg_arr = [];
          let live_srv_arr = [];
          let pending_srv_arr = [];
  
          citiesList.map((item) => {
            avl_arr.push(getAvailableServers(item.location));
            asg_arr.push(item.asg_servers);
          });
  
          if (serverStock.length > 0) {
            serverStock.map((resDataItem) => {
              if (resDataItem.location.length > 0) {
                resDataItem.location.map((locationItm) => {
                  live_srv_arr.push(locationItm.total);
                  pending_srv_arr.push(typeof locationItm.pending !== 'undefined' ? locationItm.pending : 0);
                })
              }
            })
            let live_srv_arr_total = live_srv_arr.reduce((partialSum, a) => parseInt(partialSum) + parseInt(a), 0);
            let pending_srv_arr_total = pending_srv_arr.reduce((partialSum, a) => parseInt(partialSum) + parseInt(a), 0);
            let asg_arr_total = asg_arr.reduce((partialSum, a) => partialSum + a, 0);
            let totalUtilizationSum = ((asg_arr_total / live_srv_arr_total) * 100).toFixed(0);
            setTotalUtil(totalUtilizationSum);
            setAssignedText(asg_arr_total);
            setTotalServerText(live_srv_arr_total);
            setPendingServerText(pending_srv_arr_total);
          }
        }
  
        if (mapGrouped) {
          listAllLocations(mapGrouped);
          createCsvData();
        
          if (citiesList) {
            let finalSitesArray = [];
            citiesList.map((citiesList_item) => {
              finalSitesArray.push(citiesList_item);
            })
            const cars1IDs = new Set(citiesList.map(({ location }) => location));
            const combined = [
              ...siteCitiesList.filter(({ location }) => !cars1IDs.has(location))
            ];
            setSiteCitiesListJSON(combined);
          }
        }
  
        let newCsvListArr = [];
        if (citiesList.length > 0 || siteCitiesListJSON.length > 0) {
          citiesList.map((citiesListItem) => {
  
            let tmpCity = {};
            tmpCity['location'] = citiesListItem.location;
            tmpCity['region'] = citiesListItem.region;
            tmpCity['val_servers'] = citiesListItem.val_servers;
            tmpCity['avl_servers'] = getNumberOfServersByLocation(tmpCity['location'], 'available');
            tmpCity['asg_servers'] = getNumberOfServersByLocation(tmpCity['location'], 'assigned');
            tmpCity['rem_servers'] = getNumberOfServersByLocation(tmpCity['location'], 'available') - getNumberOfServersByLocation(tmpCity['location'], 'assigned');
            // tmpCity['rem_servers'] = tmpCity['asg_servers'] > tmpCity['avl_servers'] ? '0' : tmpCity['avl_servers'] - tmpCity['asg_servers'];
            tmpCity['metro'] = citiesListItem.metro;
            // tmpCity['metro'] = tmpCity['asg_servers'] > tmpCity['avl_servers'] ? '?' : citiesListItem.metro;
            tmpCity['server_type'] = citiesListItem.server_type;
            // tmpCity['date'] = citiesListItem.date;
            tmpCity['utilization'] = ((getNumberOfServersByLocation(tmpCity['location'], 'assigned') / getNumberOfServersByLocation(tmpCity['location'], 'available')) * 100).toFixed(0) + "%";
            // tmpCity['utilization'] = tmpCity['asg_servers'] > tmpCity['avl_servers'] ? '0' : ((tmpCity['asg_servers'] / tmpCity['avl_servers']) * 100).toFixed(0) + "%";
            if (extraColumns.length > 0) {
              extraColumns.map((subItem) => {
                tmpCity[subItem.name] = getSkuCountByLocation(subItem.sku, tmpCity['location']);
              })
            }
  
            // tmpCity['total'] = getServerTotalCountByLocation(tmpCity['location']);
            newCsvListArr.push(tmpCity);
          })
  
          siteCitiesListJSON.map((citiesListItem) => {
            let tmpCity = {};
            tmpCity['location'] = citiesListItem.location;
            tmpCity['region'] = citiesListItem.region;
            tmpCity['metro'] = citiesListItem.metro;
            tmpCity['val_servers'] = citiesListItem.val_servers;
            tmpCity['avl_servers'] = getNumberOfServersByLocation(tmpCity['location'], 'available');
            tmpCity['asg_servers'] = getNumberOfServersByLocation(tmpCity['location'], 'assigned');
            tmpCity['rem_servers'] = getNumberOfServersByLocation(tmpCity['location'], 'available') - getNumberOfServersByLocation(tmpCity['location'], 'assigned');
            // tmpCity['avl_servers'] = getServerTotalCountByLocation(tmpCity['location']);
            // tmpCity['asg_servers'] = citiesListItem.asg_servers;
            // tmpCity['rem_servers'] = tmpCity['asg_servers'] > tmpCity['avl_servers'] ? '0' : tmpCity['avl_servers'] - tmpCity['asg_servers'];
            // tmpCity['server_type'] = citiesListItem.server_type;
            // tmpCity['date'] = citiesListItem.date;
            tmpCity['utilization'] = citiesListItem.utilization;
  
            if (extraColumns.length > 0) {
              extraColumns.map((subItem) => {
  
                tmpCity[subItem.name] = getSkuCountByLocation(subItem.sku, tmpCity['location']);
              })
            }
         
            // tmpCity['total'] = getServerTotalCountByLocation(tmpCity['location']);
            newCsvListArr.push(tmpCity);
          })
  
          var availServer = 0
          var asgServer = 0
          var remServer = 0
          
          newCsvListArr.map((serverItem, index) => {
            setAvailServer(availServer += serverItem.avl_servers)
            setAsgServer(asgServer += serverItem.asg_servers)
            setRemServer(remServer += parseInt(serverItem.rem_servers))

            // if (index < citiesList.length) {
            //   setAvailServer(availServer += serverItem.avl_servers)
            //   setAsgServer(asgServer += serverItem.asg_servers)
            //   setRemServer(remServer += parseInt(serverItem.rem_servers))
            // }else {
            //   setAvailServer(availServer += getServerTotalCountByLocation(serverItem.location))
            //   setAsgServer(asgServer += serverItem.asg_servers)
            //   setRemServer(remServer += getServerTotalCountByLocation(serverItem.location) - serverItem.asg_servers)
            // }
          })
  
  
  
          let countServer = {};
          countServer['location'] = 'Total'
          countServer['region'] = '';
          countServer['metro'] = '';
          countServer['val_servers'] = '';
          countServer['avl_servers'] = availServer;
          countServer['asg_servers'] = asgServer;
          countServer['rem_servers'] = remServer;
          // tmpCity['server_type'] = citiesListItem.server_type;
          countServer['date'] = '';
          countServer['utilization'] = '';
  
          if (extraColumns.length > 0) {
            extraColumns.map((subItem) => {
              countServer[subItem.name] = getSkuSum(subItem.sku);
              // countServer[subItem.name] = getServerCountByServerName(subItem.name, countServer['location']);
            })
            newCsvListArr.push(countServer);
            setCsvStock(newCsvListArr)
            setCsvButton(csvButton + 1);
          }
        }
      }, 1000);
       
      return () => clearInterval(interval);
    }
  })

  useEffect(() => {
    const interval = setInterval(() => {
      if (!sessionStorage.getItem('ath') || sessionStorage.getItem('ath') === '') {
        console.log('Session expired: Token missing');
        authCon.signOut()
        sessionStorage.clear();
        localStorage.clear();
        history.push('/signin');
      }
      if ((typeof authCon.authStatus !== 'undefined' && authCon.authStatus !== 1) || !authCon) {
        console.log('Session expired: Auth connection expired');
        authCon.signOut()
        sessionStorage.clear();
        localStorage.clear();
        history.push('/signin');
      }
    }, 1000);

    return () => clearInterval(interval);
  },[])

  const getAvailableServers = (locationName) => {
    let live = 0
    if (sitesServers.length > 0) {
      sitesServers.map((item) => {
        if (item.site === locationName) {
          live =  item.live
        }
      }
      );
    } 
    return live;
  }

  const getLive_PendingServers = (location_name) => {
    if (sitesServers.length > 0) {
      let lp_server = sitesServers.filter((item) => item.site === location_name);
      return lp_server;
    }
    return [];
  }

  const dialogBody = (params, markerName = '', site_name = '') => {
    let cityDetails = [];
    let countryDetails = [];
    let countryList = [];
    let dataAvailable = false;
    if (params[1] === '') {
      countryDetails = mapGrouped[params[0]];
      if (countryDetails) {
        Object.keys(countryDetails).map((idx_country, country_data) => {
          let c = countryDetails[idx_country][0];
          let locationNameArr = c.location.split(',');
          let locationNameArrNew = '';
          if (locationNameArr.length > 0) {
            let tmpMapItem = [];
            locationNameArr.map((mappingItem) => {
              tmpMapItem.push(mappingItem.trim())
            })
            if (tmpMapItem.length > 0) {
              locationNameArrNew = tmpMapItem.join(", ");
            }
          }

          let tmpCity = {};
          tmpCity['location'] = locationNameArrNew !== '' ? locationNameArrNew : c.location;
          tmpCity['avl_servers'] = getServerTotalCountByLocation(tmpCity['location']);
          tmpCity['asg_servers'] = countryDetails[idx_country].length;
          tmpCity['rem_servers'] = tmpCity['avl_servers'] - tmpCity['asg_servers'];
          tmpCity['server_type'] = c.cpu;
          tmpCity['date'] = c.date;
          tmpCity['utilization'] = ((tmpCity['asg_servers'] / tmpCity['avl_servers']) * 100).toFixed(0);
          tmpCity['empty'] = false;
          countryList.push(tmpCity);
        });
      }
    } else {
      let lp_data = getLive_PendingServers(markerName);
      let lp_dataNew = getLiveAndPendingServers(markerName);
      if (typeof mapGrouped[params[0]] != 'undefined' && typeof mapGrouped[params[0]][params[1]] != 'undefined') {
        cityDetails = mapGrouped[params[0]][params[1]];
        cityDetails[0]['live_servers'] = lp_dataNew.live;
        cityDetails[0]['pending_servers'] = lp_dataNew.pending;

        let locationNameArr = site_name.split(',');
        let locationNameArrNew = '';
        if (locationNameArr.length > 0) {
          let tmpMapItem = [];
          locationNameArr.map((mappingItem) => {
            tmpMapItem.push(mappingItem.trim())
          })
          if (tmpMapItem.length > 0) {
            locationNameArrNew = tmpMapItem.join(", ");
          }
        }
        cityDetails[0]['location'] = locationNameArrNew !== '' ? locationNameArrNew : site_name;
      }
      
      if (typeof cityDetails === 'undefined' || typeof mapGrouped[params[0]] === 'undefined' || cityDetails.length === 0) {
        cityDetails = [];
        cityDetails[0] = {};
        // cityDetails[0]['location'] = params[1] +', '+ params[0];
        cityDetails[0]['location'] = site_name;
        cityDetails[0]['avl_servers'] = 0;
        cityDetails[0]['asg_servers'] = 0;
        cityDetails[0]['rem_servers'] = 0;
        cityDetails[0]['server_type'] = '-';
        cityDetails[0]['date'] = '-';
        cityDetails[0]['utilization'] = 0;
        cityDetails[0]['live_servers'] = lp_dataNew.live;
        cityDetails[0]['pending_servers'] = lp_dataNew.pending;
        cityDetails[0]['empty'] = true;
      }
    }
    if (cityDetails.length > 0) {
      dataAvailable = true;
    }
    if (typeof countryDetails != 'undefined') {
      dataAvailable = true;
    }
    if (!dataAvailable) {
      Swal.fire({
        icon: 'error',
        text: `Sorry! There are no utilized servers in ${params[2]}.`,
      })
      return false;
    }

    if (params[1] !== '') {
      return (
        <>
          <Box className="popup-main-col">
            <Box className="popup-header">
              <Typography>{cityDetails[0].location}</Typography>
            </Box>
            <Box className="popup-body">
              <Table size="small">
                <TableRow>
                  <TableCell component="th">Location</TableCell>
                  <TableCell>{cityDetails[0].location}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th">Available Servers</TableCell>
                  <TableCell>{getNumberOfServersByLocation(cityDetails[0].location, 'available')}</TableCell>
                  {/* <TableCell>{getServerTotalCountByLocation(cityDetails[0].location)}</TableCell> */}
                </TableRow>
                <TableRow>
                  <TableCell component="th">Assigned Servers</TableCell>
                  <TableCell>{getNumberOfServersByLocation(cityDetails[0].location, 'assigned')}</TableCell>
                  {/* <TableCell>{cityDetails[0].empty ? '0' : cityDetails.length}</TableCell> */}
                </TableRow>
                <TableRow>
                  <TableCell component="th">Remaining Servers</TableCell>
                  <TableCell>{getNumberOfServersByLocation(cityDetails[0].location, 'available') - getNumberOfServersByLocation(cityDetails[0].location, 'assigned')}</TableCell>
                  {/* <TableCell>{cityDetails[0].empty ? getServerTotalCountByLocation(cityDetails[0].location) : getServerTotalCountByLocation(cityDetails[0].location) - cityDetails.length}</TableCell> */}
                </TableRow>
                <TableRow>
                  <TableCell component="th">Pending Servers</TableCell>
                  <TableCell>{cityDetails[0].pending_servers}</TableCell>
                </TableRow>
                {/* <TableRow>
                  <TableCell component="th">Date</TableCell>
                  <TableCell>{cityDetails[0].date}</TableCell>
                </TableRow> */}
                <TableRow>
                  <TableCell component="th">Server Utilization</TableCell>
                  <TableCell>{calculateUtilization(getNumberOfServersByLocation(cityDetails[0].location, 'assigned'), getNumberOfServersByLocation(cityDetails[0].location, 'available'))}</TableCell>
                  {/* <TableCell>{cityDetails[0].empty ? '0' : ((cityDetails.length / getServerTotalCountByLocation(cityDetails[0].location)) * 100).toFixed(0) === 'Infinity' ? 0 : ((cityDetails.length / getServerTotalCountByLocation(cityDetails[0].location)) * 100).toFixed(0)}%</TableCell> */}
                </TableRow>
              </Table>
            </Box>
          </Box>
        </>
      )
    } else {
      return (
        <TableContainer>
          <Table sx={{ minWidth: 650 }} size="small">
            <TableHead>
              <TableRow>
                <TableCell>Loc.</TableCell>
                <TableCell>Available Servers</TableCell>
                <TableCell>Assigned Servers</TableCell>
                <TableCell>Remaining Servers</TableCell>
                <TableCell>CPU</TableCell>
                {/* <TableCell>Date</TableCell> */}
                <TableCell>Srv. Util.</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {countryList.map((countryListItem) => {
                return (
                  <TableRow key={countryListItem.location + Math.random()} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component="th" scope="row">{countryListItem.location}</TableCell>
                    <TableCell>{getNumberOfServersByLocation(countryListItem.location, 'available')}</TableCell>
                    {/* <TableCell>{countryListItem.avl_servers}</TableCell> */}
                    <TableCell>{getNumberOfServersByLocation(countryListItem.location, 'assigned')}</TableCell>
                    {/* <TableCell>{countryListItem.asg_servers}</TableCell> */}
                    <TableCell>{getNumberOfServersByLocation(countryListItem.location, 'available') - getNumberOfServersByLocation(countryListItem.location, 'assigned')}</TableCell>
                    {/* <TableCell>{countryListItem.rem_servers}</TableCell> */}
                    <TableCell>{countryListItem.server_type}</TableCell>
                    {/* <TableCell>{countryListItem.date}</TableCell> */}
                    <TableCell>{((getNumberOfServersByLocation(countryListItem.location, 'assigned') / getNumberOfServersByLocation(countryListItem.location, 'available')) * 100).toFixed(0)}%</TableCell>
                    {/* <TableCell>{countryListItem.utilization === 'Infinity' ? 0 : countryListItem.utilization}%</TableCell> */}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )
    }
  }

  const handleViewDetails = (regionName) => {
    let outterData = [];
    if (typeof mapGrouped == 'object') {
      Object.keys(mapGrouped).map((idx_country, country_data) => {
        Object.keys(mapGrouped[idx_country]).map((idx_city, city_data) => {
          if (regionName === idx_city) {
            outterData = mapGrouped[idx_country][idx_city];
            // setServerDetailList(mapGrouped[idx_country][idx_city]);
            setServerDetailOpen(true);
          }
        });
      });
    }
    if (outterData.length) {
      let popupDataArr = [];
      outterData.map((row) => {
        let tmpArr = {};
        tmpArr['client_name'] = row.client.firstname + ' ' + row.client.lastname;
        tmpArr['client_company'] = row.client.companyname;
        tmpArr['client_email'] = row.client.email;
        tmpArr['client_date'] = row.client.datecreated;
        tmpArr['regdate'] = row.regdate;
        tmpArr['primaryIp'] = row.primaryIp;
        tmpArr['location'] = row.location;
        tmpArr['bwusage'] = row.bwusage;
        tmpArr['cpu'] = row.cpu;
        tmpArr['memory'] = row.memory;
        tmpArr['os'] = row.os;
        tmpArr['status'] = row.status;
        tmpArr['cost'] = row.cost;
        tmpArr['network'] = row.network;
        tmpArr['payment'] = row.paymentmethodname;
        popupDataArr.push(tmpArr);
      });
      const sortedList = popupDataArr.sort(dynamicSort("client_name"));
      setServerDetailList(sortedList);
    }
  }


  // const getServerCountByLocation = (serverName, location) => {
  //   if (serverStock.length > 0) {
  //     let matchedServer = '';
  //     let matchedServerCount = '';
  //     serverStock.map((serverItem) => {
  //       if (serverItem.name === serverName) {
  //         matchedServer = serverItem;
  //       }
  //     })
  //     if (matchedServer && matchedServer !== '') {
  //       if (typeof matchedServer.location !== 'undefined' && matchedServer.location.length > 0) {
  //         matchedServer.location.map((locationItem) => {
  //           if (locationItem.name === location) {
  //             matchedServerCount = locationItem.total;
  //           }
  //         })
  //       }
  //     }
  //     return matchedServerCount;
  //   }
  // }

  const getSkuCountByLocation = (sku, location) => {
    let numberOfServers = 0;
    if(srvServers.length){
      srvServers.map((item) => {
        if(item.sku == sku && item.location == location){
          numberOfServers++;
        }
      })
    }
    return numberOfServers > 0 ? numberOfServers : '';
  }

  const getServerCountByServerName = (serverName, location) => {
    if (serverStock.length > 0) {
      let matchedServer = '';
      let matchedServerCount = 0;
      serverStock.map((serverItem) => {
        if (serverItem.name === serverName) {
          matchedServer = serverItem;
        }
      })
      if (matchedServer && matchedServer !== '') {
        if (typeof matchedServer.location !== 'undefined' && matchedServer.location.length > 0) {
          matchedServer.location.map((locationItem) => {
            matchedServerCount += parseInt(locationItem.total);
          })
        }
      }
      return matchedServerCount;
    }
  }


  const getLiveAndPendingServers = (location_name) => {
    if (serverStock.length > 0) {
      let matchedServer = '';
      serverStock.map((serverItem) => {
        if (typeof serverItem.location !== 'undefined' && serverItem.location.length > 0) {
          serverItem.location.map((locationItem) => {
            if (locationItem.name === location_name) {
              matchedServer = locationItem;
            }
          })
        }
      })

      let returnArr = { live: getServerTotalCountByLocation(location_name), pending: 0 };
      if (matchedServer && matchedServer !== '') {
        returnArr['pending'] = typeof matchedServer.pending !== 'undefined' ? matchedServer.pending : 0;
      }

      return returnArr;
    }
  }

  const getServerTotalCountByLocation = (location) => {
    if (serverStock.length > 0) {
      let matchedServer = [];
      serverStock.map((serverItem) => {
        if (serverItem.location.length > 0) {
          serverItem.location.map((locationItem) => {
            if (locationItem.name === location) {
              matchedServer.push(locationItem.total);
            }
          })
        }
      })
      return matchedServer.reduce((partialSum, a) => parseInt(partialSum) + parseInt(a), 0);
    }
  }
  
  if (serverStock.length > 0 && typeof serverStock !== 'undefined') {
    var totalSvValue = serverStock.map((e) => (
      { name: e.name, total: e.location.map((loc) => loc.total).reduce((partialSum, a) => partialSum + a, 0) }
    ))
  }

  const getNumberOfServersByLocation = (location = '', type = '') => {
    if(srvServers){
      let availableServers = 0;
      let assignedServers = 0;
      if(srvServers.length){
        srvServers.map((item) => {
          if(type == 'available' && item.location == location){
            availableServers++;
          }
          if(type == 'assigned' && item.location == location && item.status == 'active'){
            assignedServers++;
          }
        })
      }
      if(type == 'available'){
        return availableServers;
      }else if(type == 'assigned'){
        return assignedServers;
      }
    }
  }

  const getSkuSum = (sku) => {
    let total = 0;
    if(srvServers.length){
      srvServers.map((item) => {
        if(item.sku == sku){
          total++;
        }
      })
    }
    return total;
  }

  const calculateUtilization = ($assignedVal, $availableVal) => {
    let returnVal = (($assignedVal / $availableVal) * 100).toFixed(0);
    if(typeof returnVal === 'NaN' || returnVal == 'NaN'){
      returnVal = 0;
    }
    return `${returnVal}%`;
  }

  return (
    <Box className="map-main-col">
      {screenLoader ? <Box className='screenLoader'><CircularProgress size={50} thickness={3} /></Box> : null}
      <div className="ev-scroll-bottom-indicator">
        <div className="ind-text">Scroll</div>
        <div className="ind-bar"></div>
      </div>
      <Box className="tur-cls">
        <ul className="servers-text-list">
          <li>Utilization Rate: {asgServer}/{availServer} Servers {availServer ? (<strong>{((asgServer / availServer) * 100).toFixed(0)}%</strong>) : null}</li>
          <li>Servers Deployed: {asgServer}</li>
          <li>Servers to be Deployed: {availServer - asgServer}</li>
          {/* <li>Utilization Rate: {assignedText}/{totalServerText} Servers (<strong>{totalUtil}%</strong>)</li>
          <li>Servers Deployed: {totalServerText}</li>
          <li>Servers to be Deployed: {pendingServerText}</li> */}
        </ul>
        {/* <TableCell>{availServer}</TableCell>
        <TableCell>{asgServer}</TableCell>
        <TableCell>{remServer}</TableCell> */}
      </Box>
      <Grid className="map-container" container>
        <Box className="cls-here">
          <ComposableMap data-tip="">
            <Geographies geography={geoUrl}>
              {({ geographies }) =>
                geographies.map(geo => {
                  if (geo.properties.CONTINENT !== 'Antarctica') {
                    return (
                      <Geography key={geo.rsmKey + Math.random()} geography={geo} fill={'gray'} stroke="#000"
                        onMouseEnter={() => {
                          const { name } = geo.properties;
                          setTooltipContent(`${name}`);
                        }}
                        onMouseLeave={() => {
                          setTooltipContent("");
                        }}
                        onClick={() => {
                          const { name, ISO_A2 } = geo.properties;
                          if (dialogBody([geo.properties['Alpha-2'], '', geo.properties['Alpha-2']])) {
                            setDialogContent(dialogBody([geo.properties['Alpha-2'], '', geo.properties['Alpha-2']]));
                            setOpen(true);
                          }
                        }}
                        style={{
                          default: {
                            fill: "#191d2a",
                            outline: "none"
                          },
                          hover: {
                            fill: "#0056ff",
                            outline: "none"
                          },
                          pressed: {
                            fill: "#0056ff",
                            outline: "none"
                          }
                        }} />
                    )
                  }
                })
              }
            </Geographies>
            {markers.map(({ name, coordinates, markerOffset, params }) => (
              <Marker key={name + Math.random()} coordinates={coordinates} onClick={() => {
                if (dialogBody(params, '', name)) {
                  setDialogContent(dialogBody(params, name, name));
                  setOpen(true);
                }
              }} onMouseEnter={() => {
                setTooltipContent(`${params[1]}, ${params[0]}`);
              }}>
                {JSON.stringify(coordinates)}
                <g fill="#0097fe" stroke="#fff" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" transform={`translate(-12, -24)`}>
                  {/* <g fill="#0097fe" stroke="#fff" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" transform={`translate(${coordinates[0]}px, ${coordinates[1]}px)`}> */}
                  {/* <circle cx="12" cy="10" r="3" /> */}
                  <circle cx="12" cy="20" r="3" />
                  {/* <path d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z" /> */}
                </g>
                <text textAnchor="middle" y={markerOffset} style={{ fontFamily: "system-ui", fill: "#5D5A6D" }}>
                  {/* {coordinates[0]} , {coordinates[]} */}
                </text>
              </Marker>
            ))}
          </ComposableMap>
        </Box>
      </Grid>
      <Grid container>
        <Box className="w-100 map-table-list" sx={{ p: 10 }}>
          <CSVLink title="Export CSV" className={csvButton < 2 ? 'disabledBtn csv-link ' : 'csv-link'} filename={"Solana-Foundation.csv"} data={csvStock} headers={csvHeaders}>
            {csvButton < 2 ? 'Processing...' : 'Export CSV'}
          </CSVLink>
          <CSVLink title="Solana Edgevana Client List" style={{ marginRight: 4, }} className={csvButton < 2 ? 'disabledBtn csv-link' : 'csv-link'} filename={"Solana-Client.csv"} data={clientStock} headers={clientHeaders}>
            {csvButton < 2 ? 'Processing...' : 'Solana Edgevana Client List'}
          </CSVLink>
          <TableContainer className="popup-main-col popup-main-col-borderless link-table hover-table">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Region</TableCell>
                  <TableCell>Metro</TableCell>
                  <TableCell>Available Servers</TableCell>
                  <TableCell>Assigned Servers</TableCell>
                  <TableCell>Remaining Servers</TableCell>
                  <TableCell>Srv. Util.</TableCell>
                  {extraColumns.length > 0 ? extraColumns.map((columnItem) => {
                    return (
                      <TableCell key={columnItem + Math.random()}>{columnItem.name}</TableCell>
                    )
                  }) : null}
                </TableRow>
              </TableHead>
              <TableBody>
                {citiesList ? citiesList.map((row) => (
                  <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} onClick={() => handleViewDetails(row.metro)}>
                    <TableCell>{row.region}</TableCell>
                    <TableCell>{getNumberOfServersByLocation(row.location, 'assigned') > getNumberOfServersByLocation(row.location, 'available') ? `${row.metro}*` : row.metro}</TableCell>
                    {/* <TableCell>{getServerTotalCountByLocation(row.location)}</TableCell> */}
                    <TableCell>{getNumberOfServersByLocation(row.location, 'available')}</TableCell>
                    <TableCell>{getNumberOfServersByLocation(row.location, 'assigned')}</TableCell>
                    {/* <TableCell>{row.asg_servers}</TableCell> */}
                    <TableCell>{getNumberOfServersByLocation(row.location, 'available') - getNumberOfServersByLocation(row.location, 'assigned')}</TableCell>
                    {/* <TableCell>{row.asg_servers > getServerTotalCountByLocation(row.location) ? '0' : getServerTotalCountByLocation(row.location) - row.asg_servers}</TableCell> */}
                    <TableCell>{((getNumberOfServersByLocation(row.location, 'assigned') / getNumberOfServersByLocation(row.location, 'available')) * 100).toFixed(0)}%</TableCell>
                    {/* <TableCell>{row.asg_servers > getServerTotalCountByLocation(row.location) ? '0' : ((row.asg_servers / getServerTotalCountByLocation(row.location)) * 100).toFixed(0)}%</TableCell> */}
                    {extraColumns.length > 0 ? extraColumns.map((columnItem) => {
                      return (
                        <TableCell key={columnItem + Math.random()}>{getSkuCountByLocation(columnItem.sku, row.location)}</TableCell>
                        // <TableCell key={columnItem + Math.random()}>{getServerCountByLocation(columnItem.name, row.location)}</TableCell>
                      )
                    }) : null}
                  </TableRow>
                )) : null}

                {siteCitiesListJSON ? siteCitiesListJSON.map((row) => (
                  <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} onClick={() => handleViewDetails(row.metro)}>
                    {/* <TableCell component="th" scope="row">{row.location}</TableCell> */}

                    <TableCell>{row.region}</TableCell>
                    <TableCell>{row.metro}</TableCell>
                    <TableCell>{getNumberOfServersByLocation(row.location, 'available')}</TableCell>
                    <TableCell>{getNumberOfServersByLocation(row.location, 'assigned')}</TableCell>
                    <TableCell>{getNumberOfServersByLocation(row.location, 'available') - getNumberOfServersByLocation(row.location, 'assigned')}</TableCell>
                    {/* <TableCell>{row.server_type}</TableCell> */}
                    {/* <TableCell>{row.date}</TableCell> */}
                    <TableCell>{calculateUtilization(getNumberOfServersByLocation(row.location, 'assigned'), getNumberOfServersByLocation(row.location, 'available'))}</TableCell>
                    {extraColumns.length > 0 ? extraColumns.map((columnItem) => {
                      return (
                        <TableCell key={columnItem + Math.random()}>{getSkuCountByLocation(columnItem.sku, row.location)}</TableCell>
                        // <TableCell key={columnItem + Math.random()}>{getServerCountByLocation(columnItem.name, row.location)}</TableCell>
                      )
                    }) : null}
                    {/* <TableCell>{getServerTotalCountByLocation(row.location)}</TableCell> */}
                  </TableRow>
                )) : null}

                <TableRow>
                  <TableCell colSpan={2} >Total</TableCell>
                  <TableCell>{availServer}</TableCell>
                  <TableCell>{asgServer}</TableCell>
                  <TableCell>{remServer}</TableCell>
                  <TableCell colSpan={1}></TableCell>

                  {extraColumns.length > 0 ? extraColumns.map((columnItem) => {
                    return (
                      // <TableCell key={columnItem + Math.random()}>Test</TableCell>
                      <TableCell key={columnItem + Math.random()}>{getSkuSum(columnItem.sku)}</TableCell>
                    )
                  }) : null}

                  {/* {(typeof totalSvValue !== 'undefined' && totalSvValue.length > 0) && totalSvValue.map((e) => <TableCell key={e.total + Math.random()}>{e.total}</TableCell>)} */}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Grid>

      <Dialog className="dialog-main-area" maxWidth='lg' open={serverDetailOpen} onClose={() => setServerDetailOpen(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle disableTypography className='modal-close-btn lg'>
          <span onClick={() => setServerDetailOpen(false)}>X</span>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>

            <Box className="w-100" sx={{ p: 10 }}>
              <TableContainer className="popup-main-col popup-main-col-borderless valign-top-table">
                <Table sx={{ minWidth: 1100 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Client</TableCell>
                      <TableCell>Registration Date</TableCell>
                      <TableCell>Location</TableCell>
                      <TableCell>Bandwidth</TableCell>
                      <TableCell>CPU</TableCell>
                      {/* <TableCell>Type</TableCell> */}
                      <TableCell>Memory</TableCell>
                      <TableCell>Network</TableCell>
                      <TableCell>IP</TableCell>
                      <TableCell>OS</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Cost</TableCell>
                      <TableCell>Payment Type</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {serverDetailList ? serverDetailList.map((row) => {
                      if(row.status == 'active'){
                        return (
                          <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} onClick={() => handleViewDetails(row.metro)}>
                            <TableCell>
                              <strong>Name:</strong> {row.client_name}<br />
                              <strong>Company Name:</strong> {row.client_company}<br />
                              <strong>Email:</strong> {row.client_email}<br />
                              <strong>Date:</strong> {row.client_date}<br />
                            </TableCell>
                            <TableCell>{row.regdate}</TableCell>
                            <TableCell>{row.location}</TableCell>
                            <TableCell>{row.bwusage}</TableCell>
                            <TableCell>{row.cpu}</TableCell>
                            {/* <TableCell>{row.deviceType}</TableCell> */}
                            <TableCell>{row.memory}</TableCell>
                            <TableCell>{row.network}</TableCell>
                            <TableCell>{row.primaryIp}</TableCell>
                            <TableCell>{row.os}</TableCell>
                            <TableCell>{row.status}</TableCell>
                            <TableCell>{row.cost}</TableCell>
                            <TableCell>{row.payment}</TableCell>
                          </TableRow>
                        )
                      }
                    }) : null}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>

          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Box>
  )
}
