import React, { useEffect, useState, useContext } from "react";
import { useHistory } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import MapChart from '../components/MapChart'
import ReactTooltip from "react-tooltip";
import { Dialog, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';

import { AuthContext } from '../contexts/authContext'
import Logo from '../assets/img/logo.webp';
import FooterLogo from '../assets/img/footer-logo.webp';

const Dashboard: React.FunctionComponent = () => {
  const [content, setContent] = useState("");
  const [open, setOpen] = React.useState(false);
  const [dialogContent, setDialogContent] = useState("");
  const [dialogTitle, setDialogTitle] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);
  const history = useHistory()
  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openAppBar = Boolean(anchorEl);

  const authCon = useContext(AuthContext)

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (authCon) {
      if (authCon.authStatus === 1) {
        setLoggedIn(true);
      }
    }
  })

  function signOutClicked() {
    authCon.signOut()
    sessionStorage.clear();
    localStorage.clear();
    history.push('/signin');
  }

  return (
    <>
      <Grid container className="app-header">
        <AppBar position="static">
          <Toolbar>
            {/* <IconButton edge="start" color="inherit" aria-label="menu">
              <MenuIcon />
            </IconButton> */}
            <Typography variant="h6" className="mui-logo">
              <img src={Logo} alt="" />
            </Typography>
            <div>
              <IconButton onClick={signOutClicked} color="inherit">
                <AccountCircle /> Sign Out
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
      </Grid>
      <Grid container>
        <Grid container className="block-div">
          <Box className="location-dialog">
            <MapChart setTooltipContent={setContent} setDialogContent={setDialogContent} setOpen={setOpen} setDialogTitle={setDialogTitle} />
            <ReactTooltip textColor='#fff' backgroundColor='rgba(0, 86, 255, 0.5)' borderColor='rgba(0, 86, 255, 0.5)' arrowColor='rgba(0, 86, 255, 0.5)'>{content}</ReactTooltip>
            <Dialog maxWidth='lg' open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
              <DialogTitle disableTypography className='modal-close-btn'>
                  <span onClick={() => setOpen(false)}>X</span>
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">{dialogContent}</DialogContentText>
              </DialogContent>
            </Dialog>
          </Box>
        </Grid>
      </Grid>
      <Box className='auth-credit-text auth-dashboard'>
      <Typography className='poweredBy-text'>Powered by <a href='https://edgevana.com/' target='_blank' rel="noreferrer"><img src={FooterLogo} width="105" alt="" /></a></Typography>
      </Box>
    </>
  )
}

export default Dashboard
