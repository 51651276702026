import React, { useState, useContext } from 'react'

import { useHistory } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Alert from '@material-ui/lab/Alert';
import { CircularProgress } from '@material-ui/core/';

import { useValidCode, useValidPassword, useValidUsername } from '../../hooks/useAuthHooks'
import { Code, Password, Username } from '../../components/authComponents'

import { AuthContext } from '../../contexts/authContext'
import Logo from '../../assets/img/logo.webp';
import FooterLogo from '../../assets/img/footer-logo.webp';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles({
  root: {
    height: '100vh',
  },
})

export default function ForgotPassword() {
  const classes = useStyles()

  const { code, setCode, codeIsValid } = useValidCode('')
  const { password, setPassword, passwordIsValid } = useValidPassword('')
  const { username, setUsername, usernameIsValid } = useValidUsername('')
  const [error, setError] = useState('')
  const [reset, setReset] = useState(false)
  const [passwordSuccess, setPasswordSuccess] = useState(false)
  const [screenLoader, setScreenLoader] = useState(false)
  const [isPasswordChanged, setIsPasswordChanged] = useState(false)
  const [typeIn, setTypeIn] = useState('password')
  const [typeInConfirm, setTypeInConfirm] = useState('password')

  const {
    password: passwordConfirm,
    setPassword: setPasswordConfirm,
    passwordIsValid: passwordConfirmIsValid,
  } = useValidPassword('')

  const isValid =
    !codeIsValid ||
    code.length === 0 ||
    !usernameIsValid ||
    username.length === 0 ||
    !passwordIsValid ||
    password.length === 0 ||
    !passwordConfirmIsValid ||
    passwordConfirm.length === 0 ||
    password !== passwordConfirm

  const history = useHistory()

  const authContext = useContext(AuthContext)

  const resetPassword = async () => {
    setScreenLoader(true);
    try {
      await authContext.forgotPassword(username, code, password)
      setScreenLoader(false);
      setReset(false)
      setPasswordSuccess(true);
      setError('')
      setTimeout(() => {
        history.push('/signin')
      }, 5000)
    } catch (err) {
      setScreenLoader(false);
      if (err instanceof Error) {
        setError(err.message)
      }
    }
  }

  const updatePassword = (
    <>
      {!passwordSuccess ? <>
        <Box width="80%" m={1}>
        <Code codeIsValid={codeIsValid} setCode={setCode} />
      </Box>
      <Box width="80%" m={1}>
        <Username usernameIsValid={usernameIsValid} setUsername={setUsername} />
      </Box>
      <Box width="80%" m={1}>
        <div className='relative-wrapper'>
          <Password label="New Password" passwordIsValid={passwordIsValid} setPassword={setPassword} typeIn={typeIn} />
          <div className='password-eye-wrapper'>
            {typeIn === 'password' ? <Visibility className='password-eye' onClick={() => setTypeIn('text')} /> : <VisibilityOff className='password-eye' onClick={() => setTypeIn('password')} />}
          </div>
        </div>
      </Box>
      <Box width="80%" m={1}>
        <div className='relative-wrapper'>
          <Password label="Confirm Password" passwordIsValid={passwordConfirmIsValid} setPassword={setPasswordConfirm} typeIn={typeInConfirm} />
          <div className='password-eye-wrapper'>
            {typeInConfirm === 'password' ? <Visibility className='password-eye' onClick={() => setTypeInConfirm('text')} /> : <VisibilityOff className='password-eye' onClick={() => setTypeInConfirm('password')} />}
          </div>
        </div>
      </Box>

      {/* Error */}
      {error ? <Box m={1}>
        <Alert severity="error">{error}</Alert>
      </Box> : null}

      {/* Buttons */}
      <Box mt={2}>
        <Grid container direction="row" justify="center">
          {/* <Box m={1}>
            <Button onClick={() => history.goBack()} color="secondary" variant="contained">
              Cancel
            </Button>
          </Box> */}
          <Box m={1}>
            <Button disabled={isValid} color="primary" variant="contained" onClick={resetPassword}>
              Change Password
            </Button>
          </Box>
        </Grid>
      </Box>
      </> : null}
      

      {passwordSuccess ? <Alert severity="success">Your password has been changed successfully</Alert> : null}

      <Box className='auth-secondary-text'>
        <Typography>Remember your password? <a onClick={() => history.push('signin')}>Sign In</a></Typography>
      </Box>
    </>
  )

  const passwordReset = (
    <>
      <Typography variant="h5">{`Password Reset`}</Typography>

      <Box m={4}>
        <Button onClick={() => history.push('/signin')} color="primary" variant="contained">
          Sign In
        </Button>
      </Box>
    </>
  )

  return (
    <Grid className='authentication-box' xs={11} sm={6} lg={4} container direction="row" justify="center" alignItems="center" item>
      {screenLoader ? <Box className='screenLoader'><CircularProgress size={50} thickness={3} /></Box> : null}
      <Grid className='authentication-inner-box' container direction="column" justify="center">
        <Grid container direction="column" justify="center" alignItems="center">
          {/* Title */}
          <Box>
            <Grid container>
              <List className='auth-header'>
                <ListItem className='auth-header-logo'><img src={Logo} alt="" /></ListItem>
                <ListItem className='auth-header-text'><Typography variant="h3">Reset Password</Typography></ListItem>
              </List>
            </Grid>
          </Box>

          {!reset ? updatePassword : passwordReset}
          <Box className='auth-credit-text'>
          <Typography className='poweredBy-text'>Powered by <a href='https://edgevana.com/' target='_blank'><img src={FooterLogo} width="105" /></a></Typography>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  )
}
